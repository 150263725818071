@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Inter,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji";
  height: 100vh;
  margin: 0%;
  padding: 0%;
}
* {
  font-family: "Inter,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji";
}
body::-webkit-scrollbar {
  display: none;
}

.bg-green {
  background-color: #a3c86d;
}

.bg-yellow {
  background-color: #fdd761;
}
.bg-orange {
  background-color: #ff7857;
}

.bg-purple {
  background-color: #b17897;
}
.bg-blue {
  background-color: #7a83ee;
}

/* For WebKit browsers (Chrome, Safari) */
.scrollbar-hidden::-webkit-scrollbar {
  width: 0.5em; /* Adjust the width as needed */
}

.scrollbar-hidden::-webkit-scrollbar-thumb {
  background-color: transparent; /* Hide the thumb */
}

.scrollbar-hidden::-webkit-scrollbar-track {
  background-color: transparent; /* Hide the track */
}
